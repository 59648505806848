import { Box, Typography } from '@mui/material';
import { CardComponent, ExperienceBlock } from 'maf-react-components';
import React from 'react';
import tokenIt from '../assets/img/logo/tokenIt.png';
import B12 from '../assets/img/logo/B12.png';
import foyl from '../assets/img/logo/foyl.png';
import radio from '../assets/img/logo/radio.png';
import portfolio from '../assets/img/logo/portfolio.png';
import vortex from '../assets/img/logo/vortex.png';

const TITLE_SIZE = 35;
const TITLE_WEIGHT = 700;
const SUBTITLE_SIZE = 25;
const SUBTITLE_WEIGHT = 400;
const CONTENT_SIZE = 12;
const CONTENT_WEIGHT = 400;

const Work = () => {
    return (
        <div>
            <Typography
                variant='h2'
                sx={{
                    color: '#FFF',
                    marginBottom: '50px',
                    marginTop: '50px',
                    textAlign: 'center',
                    fontSize: '50px',
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: '400'
                }}
            >
                Work Experience
            </Typography>
            <ExperienceBlock
                date='02.2024 - Today'
                circleColor='#004E92'
                skills={['Management', 'Figma', 'Scrum']}
                texts={[
                    {
                        content: 'Redspher',
                        size: TITLE_SIZE,
                        weight: TITLE_WEIGHT
                    },
                    {
                        content: 'Lead Developer',
                        size: SUBTITLE_SIZE,
                        weight: SUBTITLE_WEIGHT
                    },
                    {
                        content: `Defining the features of the applications and estimating development timelines.\n
                        Introduction to Scrum methodology, organising poker planning, retrospectives, sprint reviews. Tracking ongoing tasks within the team and following up on actions taken to improve the working environment`,
                        size: CONTENT_SIZE,
                        weight: CONTENT_WEIGHT
                    }
                ]}
            />
            <ExperienceBlock
                date='07.2019 - 02.2024'
                circleColor='#004E92'
                skills={[
                    'react JS',
                    'Node JS',
                    'Javascript',
                    'Node Red',
                    'Nest JS',
                    'Typescript'
                ]}
                texts={[
                    {
                        content: 'Redspher',
                        size: TITLE_SIZE,
                        weight: TITLE_WEIGHT
                    },
                    {
                        content: 'Web Developer Full stack',
                        size: SUBTITLE_SIZE,
                        weight: SUBTITLE_WEIGHT
                    },
                    {
                        content: `Developing a logistics tool with a React JS front-end and a Node Red API\n
                        Transitioning a legacy JavaScript application to React JS\n
                        Working on the migration of a node-red API to a NestJS`,
                        size: CONTENT_SIZE,
                        weight: CONTENT_WEIGHT
                    }
                ]}
            />
            <ExperienceBlock
                date='11.2017 - 06.2019'
                circleColor='#004E92'
                skills={[
                    'Ruby On Rails',
                    'Angular JS',
                    'Javascript',
                    'Cordova'
                ]}
                texts={[
                    {
                        content: 'Waow!',
                        size: TITLE_SIZE,
                        weight: TITLE_WEIGHT
                    },
                    {
                        content: 'Web Developer Full stack',
                        size: SUBTITLE_SIZE,
                        weight: SUBTITLE_WEIGHT
                    },
                    {
                        content: `Contributing to the development of a web application with a Ruby on Rails back-end\n
                  Discovering tools such as Cordova for making the application portable to a mobile version\n
                  Developing a Python OCR algorithm for CVs as part of an alternative company project\n
                  Leading a team of 3 developers in order to develop my management skills`,
                        size: CONTENT_SIZE,
                        weight: CONTENT_WEIGHT
                    }
                ]}
            />
            <ExperienceBlock
                date='04.2017 - 07.2017'
                circleColor='#004E92'
                skills={['PHP', 'HTML5', 'CSS3', 'MySQL']}
                texts={[
                    {
                        content: 'Aztek',
                        size: TITLE_SIZE,
                        weight: TITLE_WEIGHT
                    },
                    {
                        content: 'Web Developer Full stack',
                        size: SUBTITLE_SIZE,
                        weight: SUBTITLE_WEIGHT
                    },
                    {
                        content: `Creation of a showcase website with user authentication and profile management  in PHP.`,
                        size: CONTENT_SIZE,
                        weight: CONTENT_WEIGHT
                    }
                ]}
            />
            <Typography
                variant='h2'
                sx={{
                    color: '#FFF',
                    marginBottom: '50px',
                    marginTop: '50px',
                    textAlign: 'center',
                    fontSize: '50px',
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: '400'
                }}
            >
                Secondary Experiences
            </Typography>
            <Box
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    margin: 'auto',
                    marginBottom: '100px'
                }}
            >
                <Box
                    style={{
                        width: '400px',
                        margin: '10px auto'
                    }}
                >
                    <CardComponent
                        logo={vortex}
                        title={'Mini games website'}
                        text={
                            'Creation of a mini games site in react JS and Express JS backend. Equipped with a user authentication system with a MariaDB database. https://vortexgames.fr/'
                        }
                    />
                </Box>
                <Box
                    style={{
                        width: '400px',
                        margin: '10px auto'
                    }}
                >
                    <CardComponent
                        logo={portfolio}
                        title={'Portfolio and VPS management'}
                        text={
                            "Deployment and dockerization of web applications including my portfolio. Nginx configuration for redirections, and security via Let's Encrypt."
                        }
                    />
                </Box>
                <Box
                    style={{
                        width: '400px',
                        margin: '10px auto'
                    }}
                >
                    <CardComponent
                        logo={tokenIt}
                        title={'UX/UI designer Token It'}
                        text={
                            'In charge of the graphic redesign of an NFT marketplace application. Creating promotional posts for social media.'
                        }
                    />
                </Box>
                <Box
                    style={{
                        width: '400px',
                        margin: '10px auto'
                    }}
                >
                    <CardComponent
                        logo={B12}
                        title={'Formation B12 Consulting'}
                        text={
                            'Training to implement a Python OCR algorithm for CVs equipped with a machine learning tool.'
                        }
                    />
                </Box>
                <Box
                    style={{
                        width: '400px',
                        margin: '10px auto'
                    }}
                >
                    <CardComponent
                        logo={foyl}
                        title={'Amateur E-Sports Team Leader'}
                        text={
                            'Managed a team of around twenty players. Organized and participated in several amateur tournaments.'
                        }
                    />
                </Box>
                <Box
                    style={{
                        width: '400px',
                        margin: '10px auto'
                    }}
                >
                    <CardComponent
                        logo={radio}
                        title={'Web Radio Host'}
                        text={
                            'Hosted a 2-hour weekly show. Prepared and implemented a regular program.'
                        }
                    />
                </Box>
            </Box>
        </div>
    );
};

export default Work;
