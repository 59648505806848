import React from 'react';
import './ScrollStatus.css';

const ScrollStatus = ({ statusList, currentStatus }) => {
    return (
        <div className="scroll-status">
            {statusList.map((status, _index) => (
                <div
                    key={status}
                    className={`dot ${status === currentStatus ? 'active' : ''}`}
                ></div>
            ))}
        </div>
    );
};

export default ScrollStatus;
