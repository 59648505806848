import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TitlePortfolio, StyledButton } from 'maf-react-components';
import './Navbar.css';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const Navbar = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const list = () => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: '10px'
            }}
            onClick={() => setDrawerOpen(false)}
            onKeyDown={() => setDrawerOpen(false)}
        >
            <StyledButton
                className='styled-button'
                onClick={() => navigate('/')}
            >
                About
            </StyledButton>
            <StyledButton
                className='styled-button'
                onClick={() => navigate('/work')}
            >
                Work
            </StyledButton>
            <StyledButton
                className='styled-button'
                onClick={() => navigate('/leisure')}
            >
                My hobbies
            </StyledButton>
            <a
                href='/assets/pdf/CV.pdf'
                download='Mafrica-CV.pdf'
                style={{ textDecoration: 'none' }}
            >
                <StyledButton icon={<FileDownloadOutlinedIcon />}>
                    My Resume
                </StyledButton>
            </a>
        </Box>
    );

    return (
        <div className='navbar'>
            <Box
                onClick={() => navigate('/')}
                sx={{
                    cursor: 'pointer'
                }}
            >
                <TitlePortfolio>maf</TitlePortfolio>
            </Box>
            {isMobile ? (
                <>
                    <IconButton
                        edge='start'
                        color='inherit'
                        aria-label='menu'
                        onClick={() => setDrawerOpen(true)}
                    >
                        <MenuIcon sx={{ color: '#ffffff' }} />
                    </IconButton>
                    <Drawer
                        anchor='right'
                        open={drawerOpen}
                        onClose={() => setDrawerOpen(false)}
                        PaperProps={{
                            style: {
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                height: 'fit-content',
                                marginTop: '70px',
                                marginRight: '50px'
                            }
                        }}
                    >
                        {list()}
                    </Drawer>
                </>
            ) : (
                <div className='buttons'>
                    <StyledButton
                        className='styled-button'
                        onClick={() => navigate('/')}
                    >
                        About
                    </StyledButton>
                    <StyledButton
                        className='styled-button'
                        onClick={() => navigate('/work')}
                    >
                        Work
                    </StyledButton>
                    <StyledButton
                        className='styled-button'
                        onClick={() => navigate('/leisure')}
                    >
                        My hobbies
                    </StyledButton>
                    <a
                        href='/assets/pdf/CV.pdf'
                        download='Mafrica-CV.pdf'
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledButton icon={<FileDownloadOutlinedIcon />}>
                            My Resume
                        </StyledButton>
                    </a>
                </div>
            )}
        </div>
    );
};

export default Navbar;
