import React, { useState, useEffect, useRef } from 'react';
import ConcertsComponent from '../components/ConcertsComponent/ConcertsComponent';
import ClimbingComponent from '../components/ClimbingComponent/ClimbingComponent';
import TuftingComponent from '../components/TuftingComponent/TuftingComponent';
import ScrollStatus from '../components/ScrollStatus/ScrollStatus';

const animationDuration = 1000;
const scrollDebounceTime = 2000;

function Hobbies() {
    const [hobbyDisplay, setHobbyDisplay] = useState('climbing');
    const [isExiting, setIsExiting] = useState(false);
    const scrollTimeout = useRef(null);
    const touchStartY = useRef(0);

    const hobbies = ['tufting', 'concert', 'climbing']; // Énumération des hobbies


const handleTouchStart = (event) => {
    // Enregistrer la position Y de départ
    touchStartY.current = event.touches[0].clientY;
};

const handleTouchMove = (event) => {
    if (isExiting) return;
    if (scrollTimeout.current) return;

    // Calculer la différence entre la position de départ et la position actuelle
    const touchEndY = event.touches[0].clientY;
    const deltaY = touchStartY.current - touchEndY;

    const isScrollingDown = deltaY > 0;
    const isScrollingUp = deltaY < 0;

    if (isScrollingDown || isScrollingUp) {
        setIsExiting(true); // Commencer l'animation de sortie

        scrollTimeout.current = setTimeout(() => {
            scrollTimeout.current = null;
        }, scrollDebounceTime);

        setTimeout(() => {
            setHobbyDisplay((currentDisplay) => {
                const currentIndex = hobbies.indexOf(currentDisplay);
                let nextIndex;

                if (isScrollingDown) {
                    nextIndex = (currentIndex + 1) % hobbies.length;
                } else {
                    nextIndex =
                        (currentIndex - 1 + hobbies.length) %
                        hobbies.length;
                }

                return hobbies[nextIndex];
            });

            setIsExiting(false); // Fin de l'animation de sortie
        }, animationDuration);
    }
};

    const handleWheel = (event) => {
        // Ne rien faire si une animation de sortie est en cours
        if (isExiting) return;

        // Ignorer l'événement si un scroll a déjà été traité récemment
        if (scrollTimeout.current) return;

        // Utiliser deltaY pour déterminer la direction de la molette de la souris
        const isScrollingDown = event.deltaY > 0;
        const isScrollingUp = event.deltaY < 0;

        if (isScrollingDown || isScrollingUp) {
            setIsExiting(true); // Commencer l'animation de sortie

            // Définir un timeout pour empêcher le scroll de se produire trop rapidement
            scrollTimeout.current = setTimeout(() => {
                scrollTimeout.current = null;
            }, scrollDebounceTime);

            setTimeout(() => {
                setHobbyDisplay((currentDisplay) => {
                    const currentIndex = hobbies.indexOf(currentDisplay);
                    let nextIndex;

                    if (isScrollingDown) {
                        nextIndex = (currentIndex + 1) % hobbies.length;
                    } else {
                        nextIndex =
                            (currentIndex - 1 + hobbies.length) %
                            hobbies.length;
                    }

                    return hobbies[nextIndex];
                });

                setIsExiting(false); // Fin de l'animation de sortie
            }, animationDuration);
        }
    };

    useEffect(() => {
        // Ajouter l'event listener de wheel
        window.addEventListener('wheel', handleWheel, { passive: false });

        // Nettoyage de l'event listener lors du démontage du composant
        return () => {
            window.removeEventListener('wheel', handleWheel);
            // S'assurer de nettoyer le timeout si le composant est démonté
            if (scrollTimeout.current) {
                clearTimeout(scrollTimeout.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.addEventListener('touchstart', handleTouchStart, { passive: false });
        window.addEventListener('touchmove', handleTouchMove, { passive: false });
        
        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchmove', handleTouchMove);
            if (scrollTimeout.current) {
                clearTimeout(scrollTimeout.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='hobby-page'>
            <div style={{ height: '100%' }}>
                <ScrollStatus statusList={hobbies} currentStatus={hobbyDisplay} />
                {hobbyDisplay === 'concert' ? (
                    <ConcertsComponent exit={isExiting}></ConcertsComponent>
                ) : hobbyDisplay === 'climbing' ? (
                    <ClimbingComponent exit={isExiting}></ClimbingComponent>
                ) : hobbyDisplay === 'tufting' ? (
                    <TuftingComponent exit={isExiting}></TuftingComponent>
                ) : null}
                <div className='scroll-arrow' style={{ textAlign: 'center',position: 'absolute', bottom: '10px', left: '5%', color: 'white' }}>
                    <p style={{ textAlign: 'center', color: 'white', fontFamily: 'Montserrat' }}>scroll</p>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 18L4 10H8V4H16V10H20L12 18Z" fill="currentColor"/>
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default Hobbies;
