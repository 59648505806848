import React from 'react';
import { Custom3DTitle, Polaroid } from 'maf-react-components';
import './Concerts.css';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import concert2 from '../../assets/img/concerts/concert2.JPEG';
import concert3 from '../../assets/img/concerts/concert3.JPG';
import concert4 from '../../assets/img/concerts/concert4.JPG';
import concert5 from '../../assets/img/concerts/concert5.jpg';

const VynilSVG = ({ color = '#FFEACC', ...props }) => {
    return (
        <svg
            {...props}
            viewBox='0 0 351 351'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clip-path='url(#clip0_256_1093)'>
                <path
                    d='M103.965 279.71C161.539 319.042 240.096 304.253 279.428 246.679C318.759 189.105 303.971 110.547 246.397 71.2159C188.822 31.8845 110.265 46.6731 70.9335 104.247C31.602 161.821 46.3906 240.379 103.965 279.71Z'
                    fill='#242424'
                />
                <path
                    d='M105.943 276.815C49.8283 238.48 35.3002 161.736 73.5565 105.736C111.813 49.7357 188.586 35.3638 244.701 73.698C300.815 112.032 315.343 188.776 277.087 244.777C238.83 300.777 162.057 315.149 105.943 276.815Z'
                    fill='#0F161A'
                />
                <g opacity='0.2'>
                    <path
                        d='M152.44 54.5191C121.473 60.3385 92.7302 77.9362 73.6037 105.934C54.4772 133.932 48.5354 167.106 54.3744 198.069L175.245 175.369L152.44 54.5191Z'
                        fill='#588199'
                    />
                    <path
                        d='M276.886 244.805C296.011 216.809 301.956 183.634 296.115 152.67L175.245 175.369L198.052 296.221C229.018 290.402 257.76 272.801 276.886 244.805Z'
                        fill='#588199'
                    />
                </g>
                <path
                    d='M148.409 214.208C126.853 199.482 121.296 169.965 136.023 148.409C150.749 126.853 180.265 121.296 201.821 136.022C223.378 150.748 228.934 180.265 214.208 201.821C199.482 223.377 169.966 228.934 148.409 214.208Z'
                    fill='#66401E'
                />
                <path
                    d='M152.007 209.385C133.302 196.606 128.481 170.994 141.259 152.29C154.037 133.586 179.648 128.763 198.354 141.541C217.062 154.321 221.88 179.932 209.103 198.637C196.325 217.341 170.715 222.165 152.007 209.385Z'
                    fill={color}
                />
                <path
                    d='M173.485 177.945C174.856 178.882 176.789 178.437 177.804 176.952C178.818 175.467 178.53 173.504 177.159 172.567C175.788 171.631 173.854 172.076 172.84 173.561C171.825 175.046 172.114 177.009 173.485 177.945Z'
                    fill='#242424'
                />
            </g>
            <defs>
                <clipPath id='clip0_256_1093'>
                    <rect
                        width='252'
                        height='252'
                        fill='white'
                        transform='translate(142.149) rotate(34.3387)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
const ConcertsComponent = ({ exit }) => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    let fontSize;
    if (isSm) {
        fontSize = '60px';
    } else if (isMd) {
        fontSize = '100px';
    } else {
        fontSize = '150px';
    }

    return (
        <div style={{ height: '100%' }}>
            <div
                className={`${
                    exit ? 'title-container-exit' : 'title-container'
                } `}
            >
                <Custom3DTitle title='CONCERTS' fontSize={fontSize} />
            </div>
            <div className='content-container'>
                <div className='vynils-container'>
                    <div className='vynils-relative-container'>
                        <VynilSVG
                            color={'#F2CCFF'}
                            className={`vynils vynil1 ${
                                exit ? 'vynil1-exit' : ''
                            } `}
                        />
                        <VynilSVG
                            className={`vynils vynil2 ${
                                exit ? 'vynil2-exit' : ''
                            } `}
                        />
                        <VynilSVG
                            color={'#80CED3'}
                            className={`vynils vynil3 ${
                                exit ? 'vynil3-exit' : ''
                            } `}
                        />
                        <VynilSVG
                            color={'#B8FABB'}
                            className={`vynils vynil4 ${
                                exit ? 'vynil4-exit' : ''
                            } `}
                        />
                    </div>
                </div>

                <div className='polaroids-container'>
                    <div
                        className={`polaroidConcert polaroid1Concert ${
                            exit ? 'polaroid1Concert-exit' : ''
                        } `}
                    >
                        <Polaroid image={concert4} text={''} />
                    </div>
                    <div
                        className={`polaroidConcert polaroid2Concert ${
                            exit ? 'polaroid2Concert-exit' : ''
                        } `}
                    >
                        <Polaroid
                            image={concert2}
                            text={<p className='polaroid-text-concert'>LET'S ROCK!</p>}
                        />
                    </div>
                    <div
                        className={`polaroidConcert polaroid3Concert ${
                            exit ? 'polaroid3Concert-exit' : ''
                        } `}
                    >
                        <Polaroid
                            image={concert3}
                            text={
                                <p className='polaroid-text-concert'>PUNK'S NOT DEAD</p>
                            }
                        />
                    </div>
                    <div
                        className={`polaroidConcert polaroid4Concert ${
                            exit ? 'polaroid4Concert-exit' : ''
                        } `}
                    >
                        <Polaroid
                            image={concert5}
                            text={
                                <p className='polaroid-original-text-concert'>
                                    Live music is a way to live in the moment
                                    and forget about any problems you may
                                    encounter. You are with thousands of people
                                    and share a moment together!
                                </p>
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConcertsComponent;
