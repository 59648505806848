import React from 'react';
import { Custom3DTitle, InstaPost } from 'maf-react-components';
import './Tufting.css';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import maf_tuft from '../../assets/img/tufting/maf_tuft.png';
import tuft1 from '../../assets/img/tufting/tuft1.JPG';
import tuft2 from '../../assets/img/tufting/tuft2.JPG';
import tuft3 from '../../assets/img/tufting/tuft3.jpg';
import tuft4 from '../../assets/img/tufting/tuft4.JPG';

import yarn1 from '../../assets/img/yarns/yarn1.png';
import yarn2 from '../../assets/img/yarns/yarn2.png';
import yarn3 from '../../assets/img/yarns/yarn3.png';
import yarn4 from '../../assets/img/yarns/yarn4.png';
import yarn5 from '../../assets/img/yarns/yarn5.png';

const TuftingComponent = ({ exit }) => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    let fontSize;
    if (isSm) {
        fontSize = '50px';
    } else if (isMd) {
        fontSize = '100px';
    } else {
        fontSize = '150px';
    }
    return (
        <div style={{ height: '100%' }}>
            <div
                className={`${
                    exit ? 'title-container-exit' : 'title-container'
                } `}
            >
                <Custom3DTitle title='TUFTING' fontSize={fontSize} />
            </div>
            <div className='content-container'>
                <div className='yarns-container'>
                    <div className='yarns-relative-container'>
                        <div
                            className={`yarns yarn1 ${
                                exit ? 'yarn1-exit' : ''
                            } `}
                        >
                            <img alt='yarn1' src={yarn1}></img>
                        </div>
                        <div
                            className={`yarns yarn2 ${
                                exit ? 'yarn2-exit' : ''
                            } `}
                        >
                            <img alt='yarn2' src={yarn2}></img>
                        </div>
                        <div
                            className={`yarns yarn3 ${
                                exit ? 'yarn3-exit' : ''
                            } `}
                        >
                            <img alt='yarn3' src={yarn3}></img>
                        </div>
                        <div
                            className={`yarns yarn4 ${
                                exit ? 'yarn4-exit' : ''
                            } `}
                        >
                            <img alt='yarn4' src={yarn4}></img>
                        </div>
                        <div
                            className={`yarns yarn5 ${
                                exit ? 'yarn5-exit' : ''
                            } `}
                        >
                            <img alt='yarn5' src={yarn5}></img>
                        </div>
                    </div>
                </div>

                <div className='instaPost-container'>
                    <div
                        className={`instaPostyarns instaPost1yarns ${
                            exit ? 'instaPost1yarns-exit' : ''
                        } `}
                    >
                        <InstaPost
                            profilePic={maf_tuft}
                            postImage={tuft1}
                            profileName={'@maf_tuft'}
                        />{' '}
                    </div>
                    <div
                        className={`instaPostyarns instaPost2yarns ${
                            exit ? 'instaPost2yarns-exit' : ''
                        } `}
                    >
                        <InstaPost
                            profilePic={maf_tuft}
                            postImage={tuft2}
                            profileName={'@maf_tuft'}
                        />{' '}
                    </div>
                    <div
                        className={`instaPostyarns instaPost3yarns ${
                            exit ? 'instaPost3yarns-exit' : ''
                        } `}
                    >
                        <InstaPost
                            profilePic={maf_tuft}
                            postImage={tuft3}
                            profileName={'@maf_tuft'}
                        />{' '}
                    </div>
                    <div
                        className={`instaPostyarns instaPost4yarns ${
                            exit ? 'instaPost4yarns-exit' : ''
                        } `}
                    >
                        <InstaPost
                            profilePic={maf_tuft}
                            postImage={tuft4}
                            profileName={'@maf_tuft'}
                        />{' '}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TuftingComponent;
