import { Box, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import profile from "../assets/img/profile.png";
import skillsPic from "../assets/img/backgrounds/skills.png";
import freeTimePic from "../assets/img/backgrounds/freeTime.png";

import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CustomBlock, CustomBlockLeft } from "maf-react-components";

const About = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "2em",
          flexDirection: { xs: "column", md: "row" },
          marginY: "40px",
        }}
      >
        <img
          src={profile}
          alt="Lucas Mafrica"
          style={{ maxWidth: isXs ? "200px" : "300px", height: "auto", borderRadius: "500px" }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "2em",
          }}
        >
          <Typography
            variant="h2"
            align="left"
            sx={{
              color: "#FFF",
              width: "100%",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontSize: isXs ? "30px" : "40px",
              fontWeight: "400",
            }}
          >
            MAFRICA Lucas
          </Typography>

          <Typography
            variant="h4"
            align="left"
            sx={{
              color: "#FFF",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontSize: isXs ? "15px" : "20px",
              fontWeight: "400",
            }}
          >
            Hello there, I'm Lucas, a passionate Full Stack Web Developer. My
            portfolio showcases my dedication to creating dynamic, efficient web
            experiences and reveals my zest for life beyond the screen.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <Box
          onClick={() => navigate("/work")}
          sx={{
            width: "40%",
            minWidth: isXs ? "340px" : "370px",
            cursor: "pointer",
            margin: "auto auto 40px",
          }}
        >
          <CustomBlock
            backgroundImage={skillsPic}
            title="All the work"
            text="Get a global vision on my professional experiences."
          />
        </Box>

        <Box
          onClick={() => navigate("/leisure")}
          sx={{
            width: "40%",
            minWidth: isXs ? "340px" : "370px",
            cursor: "pointer",
            margin: "auto auto 40px",
          }}
        >
          <CustomBlockLeft
            backgroundImage={freeTimePic}
            title="My hobbies"
            text="Discover my dynamic and creative universe!"
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          marginTop: "50px",
          marginBottom: "20px",
          gap: "1em",
          justifyContent: "center",
        }}
      >
        <Link href="mailto:lucasmafrica57@gmail.com" color="inherit">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
              color: "#ffffff",
            }}
          >
            <EmailIcon />
            {!isXs && (
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                }}
              >
                lucasmafrica57@gmail.com
              </Typography>
            )}
          </Box>
        </Link>
        <Link href="tel:+33604483177" color="inherit">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
              color: "#ffffff",
            }}
          >
            <PhoneIcon />
            {!isXs && (
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                }}
              >
                +33 6 04 48 31 77
              </Typography>
            )}
          </Box>
        </Link>
        <Link
          href="https://www.linkedin.com/in/lucas-mafrica/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon
            sx={{
              color: "#ffffff",
            }}
          />
        </Link>

        <Link
          href="https://www.instagram.com/lucasmafrica/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: "#ffffff",
          }}
        >
          <InstagramIcon />
        </Link>
      </Box>
    </>
  );
};

export default About;
