import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import './App.css';

import About from './pages/About';
import Work from './pages/Work';
import Navbar from './components/Navbar/Navbar';
import { CookieModal } from 'maf-react-components';
import cookie from './assets/img/cookie.png';
import Hobbies from './pages/Hobbies';

function App() {
    const userConsent = Cookies.get('userConsent');
    const [open, setOpen] = useState(!userConsent);
    const [analyticsInit, setAnalyticsInit] = useState(false);

    useEffect(() => {
        if (userConsent === 'accept' && !analyticsInit) {
            ReactGA.initialize('G-B7L1GG1LLN');
            setAnalyticsInit(true);
        } else if (!userConsent) {
            setOpen(true);
        }
    }, [userConsent, analyticsInit]);

    const handleCookies = (consent) => {
        setOpen(false);
        Cookies.set('userConsent', consent, { expires: 365 }); // the cookie will expire in 1 year
        if (consent === 'accept' && !analyticsInit) {
            ReactGA.initialize('G-B7L1GG1LLN');
            setAnalyticsInit(true);
        }
    };

    return (
        <Router>
            <div className='App'>
                <CookieModal
                    open={open}
                    CookiesImage={cookie}
                    handleLeftClick={() => handleCookies('accept')}
                    handleRightClick={() => handleCookies('refuse')}
                    text={'This Website Uses Cookies and Third-Party Apps'}
                    leftButtonText={'Accept'}
                    rightButtonText={'Refuse'}
                />
                <Navbar />
                <div className='body-page'>
                    <Routes>
                        <Route path='/' default element={<About />} />
                        <Route path='/work' element={<Work />} />
                    </Routes>
                </div>
                <Routes>
                    <Route path='/leisure' element={<Hobbies />} />
                </Routes>
            </div>
            <PageViewTracker analyticsInit={analyticsInit} />
        </Router>
    );
}

function PageViewTracker({ analyticsInit }) {
    const location = useLocation();

    useEffect(() => {
        const userConsent = Cookies.get('userConsent');
        if (userConsent === 'accept' && analyticsInit) {
            ReactGA.event({
                category: 'page_view',
                action: 'page_view',
                params: {
                    page_path: location.pathname,
                    page_title: document.title,
                    page_location: window.location.href
                }
            });
        }
    }, [location, analyticsInit]);

    return null;
}

export default App;
