import React from 'react';
import { Custom3DTitle, InstaPost } from 'maf-react-components';
import './Climbing.css';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import maf_climb from '../../assets/img/climb/maf_climb.png';
import climb1 from '../../assets/img/climb/climb1.jpg';
import climb2 from '../../assets/img/climb/climb2.jpg';
import climb3 from '../../assets/img/climb/climb3.jpg';

import hold1 from '../../assets/img/holds/Group.png';
import hold2 from '../../assets/img/holds/Group-1.png';
import hold3 from '../../assets/img/holds/Group-2.png';
import hold4 from '../../assets/img/holds/Group-3.png';
import hold5 from '../../assets/img/holds/Group-4.png';
import hold6 from '../../assets/img/holds/Group-5.png';
import hold7 from '../../assets/img/holds/Group-6.png';
import hold8 from '../../assets/img/holds/Group-7.png';

const ClimbingComponent = ({ exit }) => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    let fontSize;
    if (isSm) {
        fontSize = '70px';
    } else if (isMd) {
        fontSize = '100px';
    } else {
        fontSize = '150px';
    }
    return (
        <div style={{ height: '100%' }}>
            <div
                className={`${
                    exit ? 'title-container-exit' : 'title-container'
                } `}
            >
                <Custom3DTitle title='CLIMBING' fontSize={fontSize} />
            </div>
            <div className='content-container'>
                <div className='holds-container'>
                    <div className='holds-relative-container'>
                        <div
                            className={`holds hold1 ${
                                exit ? 'hold1-exit' : ''
                            } `}
                        >
                            <img alt='hold1' src={hold1}></img>
                        </div>
                        <div
                            className={`holds hold2 ${
                                exit ? 'hold2-exit' : ''
                            } `}
                        >
                            <img alt='hold2' src={hold2}></img>
                        </div>
                        <div
                            className={`holds hold3 ${
                                exit ? 'hold3-exit' : ''
                            } `}
                        >
                            <img alt='hold3' src={hold3}></img>
                        </div>
                        <div
                            className={`holds hold4 ${
                                exit ? 'hold4-exit' : ''
                            } `}
                        >
                            <img alt='hold4' src={hold4}></img>
                        </div>
                        <div
                            className={`holds hold5 ${
                                exit ? 'hold5-exit' : ''
                            } `}
                        >
                            <img alt='hold5' src={hold5}></img>
                        </div>
                        <div
                            className={`holds hold6 ${
                                exit ? 'hold6-exit' : ''
                            } `}
                        >
                            <img alt='hold6' src={hold6}></img>
                        </div>
                        <div
                            className={`holds hold7 ${
                                exit ? 'hold7-exit' : ''
                            } `}
                        >
                            <img alt='hold7' src={hold7}></img>
                        </div>
                        <div
                            className={`holds hold8 ${
                                exit ? 'hold8-exit' : ''
                            } `}
                        >
                            <img alt='hold8' src={hold8}></img>
                        </div>
                    </div>
                </div>

                <div className='instaPostClimb-container'>
                    <div
                        className={`instaPostClimb instaPost1Climb ${
                            exit ? 'instaPost1Climb-exit' : ''
                        } `}
                    >
                        <InstaPost
                            profilePic={maf_climb}
                            postImage={climb1}
                            profileName={'@maf_climb'}
                        />{' '}
                    </div>
                    <div
                        className={`instaPostClimb instaPost2Climb ${
                            exit ? 'instaPost2Climb-exit' : ''
                        } `}
                    >
                        <InstaPost
                            profilePic={maf_climb}
                            postImage={climb2}
                            profileName={'@maf_climb'}
                        />{' '}
                    </div>
                    <div
                        className={`instaPostClimb instaPost3Climb ${
                            exit ? 'instaPost3Climb-exit' : ''
                        } `}
                    >
                        <InstaPost
                            profilePic={maf_climb}
                            postImage={climb3}
                            profileName={'@maf_climb'}
                        />{' '}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClimbingComponent;
